import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from '@apollo/client/link/context';
import { createContext, memo, type FC, type PropsWithChildren } from "react"
import { MsalProvider } from '@azure/msal-react';
import jwt_decode from "jwt-decode";
import { GoogleOAuthProvider } from '@react-oauth/google';
import TagManager from "react-gtm-module"

import introspection, { useMeQuery } from "../../graphql"
import PageProvider from "../../pages"
import { msalInstance } from "../../../msalConfig"
import { useEffect } from "react"
import { useState } from "react"
import { Dispatch } from "react"
import { SetStateAction } from "react"
import { useNavigate } from "react-router-dom"

interface Token {
  name: string;
  exp: number;
}

export type Subscription = {
  id: string,
  startDate: string,
  type: string,
  prompts: number,
  updatedDate: string,
  finishDate: string,
  key: string,
}

export type User = {
  username: string,
  connectedBy: string,
  uuid: string,
  id: string,
  email: string,
  closed?: boolean,
  lastLogin: Date,
  subscription?: Subscription,
  profile?: InputMaybe<InputMaybe<ComponentProfilePersonInput>[]>,
}

type AppProps = {
  app: { api: boolean },
  user: User | undefined | null,
  setUser: (value: User | undefined) => void,
  isOpenSubscriptionModal: boolean,
  setIsOpenSubscriptionModal: Dispatch<SetStateAction<boolean>>,
  isOpenEndOfPlanModal: boolean,
  setIsOpenEndOfPlanModal: Dispatch<SetStateAction<boolean>>,
  isLoadingPage: boolean,
  setIsLoadingPage: Dispatch<SetStateAction<boolean>>,
  isBlockedNavigation: boolean,
  setIsBlockedNavigation: Dispatch<SetStateAction<boolean>>,
  isOpenLibrary: boolean,
  setIsOpenLibrary: Dispatch<SetStateAction<boolean>>,
  person: string,
  setPerson: Dispatch<SetStateAction<string>>
}

const defaultValue: AppProps = {
  app: { api: false },
  user:  undefined,
  setUser: () => {},
  isOpenSubscriptionModal: false,
  setIsOpenSubscriptionModal: () => { },
  isOpenLibrary: false,
  setIsOpenLibrary: () => { },
  isOpenEndOfPlanModal: false,
  setIsOpenEndOfPlanModal: () => {},
  person: '',
  setPerson: () => {},
  isLoadingPage: true,
  setIsLoadingPage: () => {},
  isBlockedNavigation: false,
  setIsBlockedNavigation: () => {},
}

export const Context = createContext(defaultValue)

type ContextProviderProps = PropsWithChildren<Partial<AppProps>>

const ContextProvider: FC<ContextProviderProps> = ({ children, ...props }) => {

  const { data: meData, error, loading } = useMeQuery()

  // for delete jwt token if expired or invalid
  useEffect(() => {
    let isUnauthorized
    if (error?.networkError && error.networkError instanceof Object && "response" in error.networkError) {
      isUnauthorized = error?.networkError?.response?.status === 401
    }
    if (isUnauthorized && !loading) {
      localStorage.removeItem("token")
      location.reload()
    }
  }, [error, loading])

  const [user, setUser] = useState<User | undefined>(undefined);
  const [person, setPerson] = useState<string>('');

  const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState<boolean>(false);
  const [isOpenEndOfPlanModal, setIsOpenEndOfPlanModal] = useState<boolean>(false);

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);

  const [isBlockedNavigation, setIsBlockedNavigation] = useState<boolean>(false);
  const [isOpenLibrary, setIsOpenLibrary] = useState<boolean>(false);
  const clearUserInfo = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    setUser(undefined)
  }

  useEffect(() => {
    const token: string | null = localStorage.getItem('token')
    const userJson = localStorage.getItem('user')
    if (token && userJson) {
      const decodedJwt = jwt_decode<Token>(token)
      const expiredJwtDate = new Date(decodedJwt.exp * 1000)
      console.log(expiredJwtDate)
      if (expiredJwtDate > new Date()) {
        setUser(JSON.parse(userJson))
      } else {
        clearUserInfo()
      }
    } else {
      clearUserInfo()
    }
  }, [])

  return <Context.Provider
    value={{
      user: meData?.me as User,
      setUser,
      person,
      setPerson,
      isOpenSubscriptionModal,
      setIsOpenSubscriptionModal,
      isOpenEndOfPlanModal,
      setIsOpenEndOfPlanModal,
      isBlockedNavigation,
      setIsBlockedNavigation,
      isLoadingPage,
      setIsLoadingPage,
      isOpenLibrary,
      setIsOpenLibrary,
      app: defaultValue.app,
      ...props,
    }}>{children}</Context.Provider>
}

const apiUrl = `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}` as const

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const httpLink = createHttpLink({
    uri: apiUrl,
    credentials: "same-origin",
  })

const clientOptions: ConstructorParameters<typeof ApolloClient>[0] = {
  link: authLink.concat(httpLink),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
}

const apolloClient = new ApolloClient(clientOptions)


const googleClient = import.meta.env.WEBSITE_GOOGLE_CLIENT_ID

const tagManagerArgs = {
    gtmId: import.meta.env.WEBSITE_GTM_IDENTIFIER
}

TagManager.initialize(tagManagerArgs)

const App: FC = memo(() => (
  <MsalProvider instance={msalInstance}>
    <GoogleOAuthProvider clientId={googleClient} >
      <ApolloProvider client={apolloClient}>
        <ContextProvider>
          <PageProvider />
        </ContextProvider>
      </ApolloProvider>
    </GoogleOAuthProvider>
  </MsalProvider>

))

export { ContextProvider }

export default App
