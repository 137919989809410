export const baseUrl = () => {
  return window.location.origin === 'http://localhost:8080' ? 'http://localhost:1337' : window.location.origin
  // return 'https://stage.partnerai.one/'
}

// product Checkout logic

export function SS_ProductCheckout(productId: string, userEmail: string): string {
  localStorage.setItem('strapiStripeUrl', baseUrl());
  const getRedirectUrl = baseUrl() + '/strapi-stripe/getRedirectUrl/' + productId + '/' + userEmail;
  console.log(getRedirectUrl, 'url')
  const sessionId: { id: string } = {
    id: ""
  }
  fetch(getRedirectUrl, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then(response => response.json())
    .then(response => {
      console.log(response, 'resp')
      if (response.url) {
        window.location.replace(response.url);
        sessionId.id = response.url.split("https://checkout.stripe.com/c/pay/")[0].split("#")[0]
      }
    });
  return sessionId.id
}

//  storing product payment order in strapi logic

export function SS_GetProductPaymentDetails(checkoutSessionId: string) {
  const baseUrl = localStorage.getItem('strapiStripeUrl');
  const retrieveCheckoutSessionUrl =
    baseUrl + '/strapi-stripe/retrieveCheckoutSession/' + checkoutSessionId;
  const response: {paid: boolean} = {
    paid: false
  }
  if (
    window.performance
      .getEntriesByType('navigation')
      .map(nav => (nav as any).type)
      .includes('reload')
  ) {
    console.info('website reloaded');
  } else {
    fetch(retrieveCheckoutSessionUrl, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(response => response.json())
      .then(response => {if (response.payment_status === "paid") response.paid = true});
  }

  return response.paid
}
