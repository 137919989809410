import "./DefaultLayout.less"

import markerSDK from '@marker.io/browser';
import { Layout, Row } from "antd"
import React, { FC, Fragment, Suspense, useContext, useEffect } from "react"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { Header } from "./Header"
import { useGetUserQuery } from "../../graphql"
import { Context } from "../app"
import { ResponseLoader } from "./ResponseLoader"
import { useWindowSize } from "../../hooks/useWindowSize"
import { LockScreen } from "../lockScreen"
import SubscriptionWindow from "../modals/SubscriptionWindow"
import EndOfPlanWindow from "../modals/EndOfPlanWindow";
import { sha256 } from "js-sha256"
import { Footer } from "./Footer"
import { ClosedAccountScreen } from "../closedAccountScreen"

export const DefaultLayout: FC = () => {

  const { user, setUser, isLoadingPage, isOpenSubscriptionModal, isOpenEndOfPlanModal } = useContext(Context)

  const { width } = useWindowSize()

  const MARKER_PROJECT_ID = import.meta.env.WEBSITE_MARKER_PROJECT_ID

  const getWidget = async () => {
    await markerSDK.loadWidget({
      project: MARKER_PROJECT_ID,
      source: 'snippet'
    });
  }

  // @ts-ignore
  const { data } = useGetUserQuery({variables: {id: user?.id}})
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const isAuthPage = pathname === "/signup" || pathname === "/signin"

  useEffect(() => {
    const setFullInfo = () => {
      if (data) {
        const fullUser = data.usersPermissionsUser?.data?.attributes
        if (fullUser) {
          setUser({
            uuid: fullUser.uuid || '',
            id: fullUser.id,
            username: fullUser.fullname ?? "",
            email: fullUser.email,
            closed: fullUser?.closed!,
            connectedBy: fullUser?.connectedBy || '',
            lastLogin: fullUser?.updatedAt!,
            subscription: {
              type: fullUser.subscription?.type!,
              startDate: fullUser.subscription?.startDate!,
              updatedDate: fullUser.subscription?.updatedDate!,
              finishDate: fullUser.subscription?.finishDate!,
              key: fullUser.subscription?.key!,
              id: fullUser.subscription?.ticketId!,
              prompts: fullUser.subscription?.prompts!,
            },
            profile: fullUser.person
          })
        }
      }
      getWidget()
    }

    if (user) {
      setFullInfo()
    } else {
      const navigateTo = (pathname === "/preboarding" || pathname === "/signin") ? "/signin" : "/signup"
      navigate(navigateTo, {replace: false})
    }
  }, [data])

  return (<Layout className={"default"}>
    {isLoadingPage && <Row className={'loading-page'} justify={'center'} align={'middle'}><ResponseLoader /></Row>}
    {width < 850 && <LockScreen />}
    {user?.closed && <ClosedAccountScreen />}
    {isOpenSubscriptionModal &&
      <SubscriptionWindow />
    }
    {isOpenEndOfPlanModal &&
      <EndOfPlanWindow />
    }
    {(width > 850 && !user?.closed) && <Fragment>
      <Layout.Header className={`header ${isAuthPage && "auth"}`}>
        <Header />
      </Layout.Header>
      <Layout.Content className={`${isAuthPage ? "auth" : ''}`}>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout.Content>
    </Fragment>}
  </Layout>)
}
export default DefaultLayout
