import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AvailableLlm",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentMediaInfoMediaInfo",
      "ComponentOrderedMenuGroup",
      "ComponentOrderedMenuNav",
      "ComponentProfilePerson",
      "ComponentPromptsAiResponse",
      "ComponentPromptsAvailablePrompt",
      "ComponentPromptsJourneyDetails",
      "ComponentSeasonTicketSeasonTicket",
      "ComponentSubscriptionSubscription",
      "ComponentUiCard",
      "ComponentUiElement",
      "ComponentUiGrid",
      "ComponentUiGroupSelects",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiLlmType",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSelect",
      "ComponentUiTab",
      "ComponentUiText",
      "CreditsCheckoutSession",
      "CreditsPaymentProduct",
      "GlobalSetting",
      "Home",
      "Layout",
      "Login",
      "MenuGroup",
      "MenuNavigation",
      "ModalContent",
      "Notification",
      "Onboarding",
      "PaymentHistory",
      "Prompt",
      "RequestHistory",
      "ResponseHistory",
      "StrapiStripeSsPayment",
      "StrapiStripeSsProduct",
      "SubscriptionType",
      "Tag",
      "TagsCategory",
      "TextToTextPrompt",
      "UploadFile",
      "UploadFolder",
      "UsersMenu",
      "UsersNotification",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AvailableLlm",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentMediaInfoMediaInfo",
      "ComponentOrderedMenuGroup",
      "ComponentOrderedMenuNav",
      "ComponentProfilePerson",
      "ComponentPromptsAiResponse",
      "ComponentPromptsAvailablePrompt",
      "ComponentPromptsJourneyDetails",
      "ComponentSeasonTicketSeasonTicket",
      "ComponentSubscriptionSubscription",
      "ComponentUiCard",
      "ComponentUiElement",
      "ComponentUiGrid",
      "ComponentUiGroupSelects",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiLlmType",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSelect",
      "ComponentUiTab",
      "ComponentUiText",
      "CreditsCheckoutSession",
      "CreditsPaymentProduct",
      "GlobalSetting",
      "Home",
      "Layout",
      "Login",
      "MenuGroup",
      "MenuNavigation",
      "ModalContent",
      "Notification",
      "Onboarding",
      "PaymentHistory",
      "Prompt",
      "RequestHistory",
      "ResponseHistory",
      "StrapiStripeSsPayment",
      "StrapiStripeSsProduct",
      "SubscriptionType",
      "Tag",
      "TagsCategory",
      "TextToTextPrompt",
      "UploadFile",
      "UploadFolder",
      "UsersMenu",
      "UsersNotification",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const AvailableLlmFragmentDoc = gql`
    fragment AvailableLLM on AvailableLlm {
  icon {
    data {
      id
      attributes {
        url
      }
    }
  }
  name
  model
  details
  llmType
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const ResponseHistoryFragmentDoc = gql`
    fragment ResponseHistory on ResponseHistory {
  llmResponse
  llmInput
  createdAt
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUser {
  updatedAt
  fullname
  uuid
  id
  username
  email
  closed
  connectedBy
  subscription {
    id
    type
    startDate
    prompts
    key
    updatedDate
    finishDate
    ticketId
  }
  person {
    id
    selectedValue {
      id
      key
      value
      displayValue
    }
    personName
  }
}
    `;
export const CreateCreditsPaymentSessionDocument = gql`
    mutation createCreditsPaymentSession($id: ID!, $originUrl: String!) {
  createCreditsPaymentSession(id: $id, origin: $originUrl) {
    url
  }
}
    `;
export type CreateCreditsPaymentSessionMutationFn = Apollo.MutationFunction<CreateCreditsPaymentSessionMutation, CreateCreditsPaymentSessionMutationVariables>;
export function useCreateCreditsPaymentSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCreditsPaymentSessionMutation, CreateCreditsPaymentSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCreditsPaymentSessionMutation, CreateCreditsPaymentSessionMutationVariables>(CreateCreditsPaymentSessionDocument, options);
      }
export type CreateCreditsPaymentSessionMutationHookResult = ReturnType<typeof useCreateCreditsPaymentSessionMutation>;
export type CreateCreditsPaymentSessionMutationResult = Apollo.MutationResult<CreateCreditsPaymentSessionMutation>;
export const CreateDraftResponseHistoryDocument = gql`
    mutation createDraftResponseHistory($input: CreateDraftResponseHistoryInput!) {
  createDraftResponseHistory(input: $input) {
    data {
      id
      attributes {
        ...ResponseHistory
      }
    }
  }
}
    ${ResponseHistoryFragmentDoc}`;
export type CreateDraftResponseHistoryMutationFn = Apollo.MutationFunction<CreateDraftResponseHistoryMutation, CreateDraftResponseHistoryMutationVariables>;
export function useCreateDraftResponseHistoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftResponseHistoryMutation, CreateDraftResponseHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftResponseHistoryMutation, CreateDraftResponseHistoryMutationVariables>(CreateDraftResponseHistoryDocument, options);
      }
export type CreateDraftResponseHistoryMutationHookResult = ReturnType<typeof useCreateDraftResponseHistoryMutation>;
export type CreateDraftResponseHistoryMutationResult = Apollo.MutationResult<CreateDraftResponseHistoryMutation>;
export const CreateMenuGroupDocument = gql`
    mutation createMenuGroup($input: MenuGroupInput!) {
  createMenuGroup(data: $input) {
    data {
      id
      attributes {
        order
        name
      }
    }
  }
}
    `;
export type CreateMenuGroupMutationFn = Apollo.MutationFunction<CreateMenuGroupMutation, CreateMenuGroupMutationVariables>;
export function useCreateMenuGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuGroupMutation, CreateMenuGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuGroupMutation, CreateMenuGroupMutationVariables>(CreateMenuGroupDocument, options);
      }
export type CreateMenuGroupMutationHookResult = ReturnType<typeof useCreateMenuGroupMutation>;
export type CreateMenuGroupMutationResult = Apollo.MutationResult<CreateMenuGroupMutation>;
export const CreateMenuNavigationDocument = gql`
    mutation createMenuNavigation($input: MenuNavigationInput!) {
  createMenuNavigation(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateMenuNavigationMutationFn = Apollo.MutationFunction<CreateMenuNavigationMutation, CreateMenuNavigationMutationVariables>;
export function useCreateMenuNavigationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuNavigationMutation, CreateMenuNavigationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuNavigationMutation, CreateMenuNavigationMutationVariables>(CreateMenuNavigationDocument, options);
      }
export type CreateMenuNavigationMutationHookResult = ReturnType<typeof useCreateMenuNavigationMutation>;
export type CreateMenuNavigationMutationResult = Apollo.MutationResult<CreateMenuNavigationMutation>;
export const CreatePaymentHistoryDocument = gql`
    mutation createPaymentHistory($input: PaymentHistoryInput!) {
  createPaymentHistory(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreatePaymentHistoryMutationFn = Apollo.MutationFunction<CreatePaymentHistoryMutation, CreatePaymentHistoryMutationVariables>;
export function useCreatePaymentHistoryMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentHistoryMutation, CreatePaymentHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentHistoryMutation, CreatePaymentHistoryMutationVariables>(CreatePaymentHistoryDocument, options);
      }
export type CreatePaymentHistoryMutationHookResult = ReturnType<typeof useCreatePaymentHistoryMutation>;
export type CreatePaymentHistoryMutationResult = Apollo.MutationResult<CreatePaymentHistoryMutation>;
export const CreatePromptHistoryDocument = gql`
    mutation createPromptHistory($input: CreatePromptHistoryInput!) {
  createPromptHistory(input: $input) {
    status
    responseId
    historyId
  }
}
    `;
export type CreatePromptHistoryMutationFn = Apollo.MutationFunction<CreatePromptHistoryMutation, CreatePromptHistoryMutationVariables>;
export function useCreatePromptHistoryMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromptHistoryMutation, CreatePromptHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromptHistoryMutation, CreatePromptHistoryMutationVariables>(CreatePromptHistoryDocument, options);
      }
export type CreatePromptHistoryMutationHookResult = ReturnType<typeof useCreatePromptHistoryMutation>;
export type CreatePromptHistoryMutationResult = Apollo.MutationResult<CreatePromptHistoryMutation>;
export const CreateUserFavouriteMenuDocument = gql`
    mutation createUserFavouriteMenu($input: CreateUserFavouriteMenuInput!) {
  createUserFavouriteMenu(input: $input) {
    status
  }
}
    `;
export type CreateUserFavouriteMenuMutationFn = Apollo.MutationFunction<CreateUserFavouriteMenuMutation, CreateUserFavouriteMenuMutationVariables>;
export function useCreateUserFavouriteMenuMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserFavouriteMenuMutation, CreateUserFavouriteMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserFavouriteMenuMutation, CreateUserFavouriteMenuMutationVariables>(CreateUserFavouriteMenuDocument, options);
      }
export type CreateUserFavouriteMenuMutationHookResult = ReturnType<typeof useCreateUserFavouriteMenuMutation>;
export type CreateUserFavouriteMenuMutationResult = Apollo.MutationResult<CreateUserFavouriteMenuMutation>;
export const CreateUserNotificationDocument = gql`
    mutation createUserNotification($input: UsersNotificationInput!) {
  createUsersNotification(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateUserNotificationMutationFn = Apollo.MutationFunction<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>;
export function useCreateUserNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>(CreateUserNotificationDocument, options);
      }
export type CreateUserNotificationMutationHookResult = ReturnType<typeof useCreateUserNotificationMutation>;
export type CreateUserNotificationMutationResult = Apollo.MutationResult<CreateUserNotificationMutation>;
export const DeleteMenuGroupDocument = gql`
    mutation deleteMenuGroup($id: ID!) {
  deleteMenuGroup(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteMenuGroupMutationFn = Apollo.MutationFunction<DeleteMenuGroupMutation, DeleteMenuGroupMutationVariables>;
export function useDeleteMenuGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuGroupMutation, DeleteMenuGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuGroupMutation, DeleteMenuGroupMutationVariables>(DeleteMenuGroupDocument, options);
      }
export type DeleteMenuGroupMutationHookResult = ReturnType<typeof useDeleteMenuGroupMutation>;
export type DeleteMenuGroupMutationResult = Apollo.MutationResult<DeleteMenuGroupMutation>;
export const DeleteMenuNavigationDocument = gql`
    mutation deleteMenuNavigation($id: ID!) {
  deleteMenuNavigation(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteMenuNavigationMutationFn = Apollo.MutationFunction<DeleteMenuNavigationMutation, DeleteMenuNavigationMutationVariables>;
export function useDeleteMenuNavigationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuNavigationMutation, DeleteMenuNavigationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuNavigationMutation, DeleteMenuNavigationMutationVariables>(DeleteMenuNavigationDocument, options);
      }
export type DeleteMenuNavigationMutationHookResult = ReturnType<typeof useDeleteMenuNavigationMutation>;
export type DeleteMenuNavigationMutationResult = Apollo.MutationResult<DeleteMenuNavigationMutation>;
export const EditDraftResponseHistoryDocument = gql`
    mutation editDraftResponseHistory($input: EditDraftResponseHistoryInput!) {
  editDraftResponseHistory(input: $input) {
    data {
      id
      attributes {
        ...ResponseHistory
      }
    }
  }
}
    ${ResponseHistoryFragmentDoc}`;
export type EditDraftResponseHistoryMutationFn = Apollo.MutationFunction<EditDraftResponseHistoryMutation, EditDraftResponseHistoryMutationVariables>;
export function useEditDraftResponseHistoryMutation(baseOptions?: Apollo.MutationHookOptions<EditDraftResponseHistoryMutation, EditDraftResponseHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDraftResponseHistoryMutation, EditDraftResponseHistoryMutationVariables>(EditDraftResponseHistoryDocument, options);
      }
export type EditDraftResponseHistoryMutationHookResult = ReturnType<typeof useEditDraftResponseHistoryMutation>;
export type EditDraftResponseHistoryMutationResult = Apollo.MutationResult<EditDraftResponseHistoryMutation>;
export const LoginDocument = gql`
    mutation Login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
      username
      email
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RearrangingMenuDocument = gql`
    mutation rearrangingMenu($input: RearrangingMenuInput!) {
  rearrangingMenu(input: $input) {
    success
  }
}
    `;
export type RearrangingMenuMutationFn = Apollo.MutationFunction<RearrangingMenuMutation, RearrangingMenuMutationVariables>;
export function useRearrangingMenuMutation(baseOptions?: Apollo.MutationHookOptions<RearrangingMenuMutation, RearrangingMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RearrangingMenuMutation, RearrangingMenuMutationVariables>(RearrangingMenuDocument, options);
      }
export type RearrangingMenuMutationHookResult = ReturnType<typeof useRearrangingMenuMutation>;
export type RearrangingMenuMutationResult = Apollo.MutationResult<RearrangingMenuMutation>;
export const RegisterDocument = gql`
    mutation Register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      username
      email
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const UpdateMenuGroupDocument = gql`
    mutation updateMenuGroup($input: MenuGroupInput!, $id: ID!) {
  updateMenuGroup(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateMenuGroupMutationFn = Apollo.MutationFunction<UpdateMenuGroupMutation, UpdateMenuGroupMutationVariables>;
export function useUpdateMenuGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuGroupMutation, UpdateMenuGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuGroupMutation, UpdateMenuGroupMutationVariables>(UpdateMenuGroupDocument, options);
      }
export type UpdateMenuGroupMutationHookResult = ReturnType<typeof useUpdateMenuGroupMutation>;
export type UpdateMenuGroupMutationResult = Apollo.MutationResult<UpdateMenuGroupMutation>;
export const UpdateMenuNavigationDocument = gql`
    mutation updateMenuNavigation($input: MenuNavigationInput!, $id: ID!) {
  updateMenuNavigation(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateMenuNavigationMutationFn = Apollo.MutationFunction<UpdateMenuNavigationMutation, UpdateMenuNavigationMutationVariables>;
export function useUpdateMenuNavigationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuNavigationMutation, UpdateMenuNavigationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuNavigationMutation, UpdateMenuNavigationMutationVariables>(UpdateMenuNavigationDocument, options);
      }
export type UpdateMenuNavigationMutationHookResult = ReturnType<typeof useUpdateMenuNavigationMutation>;
export type UpdateMenuNavigationMutationResult = Apollo.MutationResult<UpdateMenuNavigationMutation>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(data: $input, id: $id) {
    data {
      attributes {
        person {
          id
          personName
          selectedValue {
            id
            displayValue
            key
            value
          }
        }
      }
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export const UpdateRatePromptDocument = gql`
    mutation updateRatePrompt($input: ResponseHistoryInput!, $id: ID!) {
  updateResponseHistory(id: $id, data: $input) {
    data {
      id
      attributes {
        rating
      }
    }
  }
}
    `;
export type UpdateRatePromptMutationFn = Apollo.MutationFunction<UpdateRatePromptMutation, UpdateRatePromptMutationVariables>;
export function useUpdateRatePromptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRatePromptMutation, UpdateRatePromptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRatePromptMutation, UpdateRatePromptMutationVariables>(UpdateRatePromptDocument, options);
      }
export type UpdateRatePromptMutationHookResult = ReturnType<typeof useUpdateRatePromptMutation>;
export type UpdateRatePromptMutationResult = Apollo.MutationResult<UpdateRatePromptMutation>;
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($input: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(data: $input, id: $id) {
    data {
      id
      attributes {
        subscription {
          id
          prompts
          startDate
          type
          updatedDate
          finishDate
          key
          ticketId
        }
      }
    }
  }
}
    `;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(id: $id, data: $input) {
    data {
      attributes {
        username
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const UpdateUserMenuDocument = gql`
    mutation updateUserMenu($input: UsersMenuInput!, $id: ID!) {
  updateUsersMenu(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateUserMenuMutationFn = Apollo.MutationFunction<UpdateUserMenuMutation, UpdateUserMenuMutationVariables>;
export function useUpdateUserMenuMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMenuMutation, UpdateUserMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMenuMutation, UpdateUserMenuMutationVariables>(UpdateUserMenuDocument, options);
      }
export type UpdateUserMenuMutationHookResult = ReturnType<typeof useUpdateUserMenuMutation>;
export type UpdateUserMenuMutationResult = Apollo.MutationResult<UpdateUserMenuMutation>;
export const GetContextsDocument = gql`
    query getContexts {
  prompts(pagination: {limit: -1}) {
    data {
      attributes {
        context {
          id
          options {
            key
            value
            displayValue
          }
          categoryDisplayName
          categoryKey
        }
      }
    }
  }
}
    `;
export function useGetContextsQuery(baseOptions?: Apollo.QueryHookOptions<GetContextsQuery, GetContextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContextsQuery, GetContextsQueryVariables>(GetContextsDocument, options);
      }
export function useGetContextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContextsQuery, GetContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContextsQuery, GetContextsQueryVariables>(GetContextsDocument, options);
        }
export type GetContextsQueryHookResult = ReturnType<typeof useGetContextsQuery>;
export type GetContextsLazyQueryHookResult = ReturnType<typeof useGetContextsLazyQuery>;
export type GetContextsQueryResult = Apollo.QueryResult<GetContextsQuery, GetContextsQueryVariables>;
export const CreditsPaymentProductsDocument = gql`
    query creditsPaymentProducts {
  creditsPaymentProducts {
    data {
      id
      attributes {
        name
        quantity
        price
      }
    }
  }
}
    `;
export function useCreditsPaymentProductsQuery(baseOptions?: Apollo.QueryHookOptions<CreditsPaymentProductsQuery, CreditsPaymentProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditsPaymentProductsQuery, CreditsPaymentProductsQueryVariables>(CreditsPaymentProductsDocument, options);
      }
export function useCreditsPaymentProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditsPaymentProductsQuery, CreditsPaymentProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditsPaymentProductsQuery, CreditsPaymentProductsQueryVariables>(CreditsPaymentProductsDocument, options);
        }
export type CreditsPaymentProductsQueryHookResult = ReturnType<typeof useCreditsPaymentProductsQuery>;
export type CreditsPaymentProductsLazyQueryHookResult = ReturnType<typeof useCreditsPaymentProductsLazyQuery>;
export type CreditsPaymentProductsQueryResult = Apollo.QueryResult<CreditsPaymentProductsQuery, CreditsPaymentProductsQueryVariables>;
export const GetGlobalSettingDocument = gql`
    query getGlobalSetting {
  globalSetting {
    data {
      attributes {
        getAllAIResponses
        defaultRoute
        initialTokens
      }
    }
  }
}
    `;
export function useGetGlobalSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalSettingQuery, GetGlobalSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalSettingQuery, GetGlobalSettingQueryVariables>(GetGlobalSettingDocument, options);
      }
export function useGetGlobalSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalSettingQuery, GetGlobalSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalSettingQuery, GetGlobalSettingQueryVariables>(GetGlobalSettingDocument, options);
        }
export type GetGlobalSettingQueryHookResult = ReturnType<typeof useGetGlobalSettingQuery>;
export type GetGlobalSettingLazyQueryHookResult = ReturnType<typeof useGetGlobalSettingLazyQuery>;
export type GetGlobalSettingQueryResult = Apollo.QueryResult<GetGlobalSettingQuery, GetGlobalSettingQueryVariables>;
export const GetLlMsDocument = gql`
    query getLLMs {
  availableLlms(sort: "createdAt:desc") {
    data {
      id
      attributes {
        name
        model
        createdAt
      }
    }
  }
}
    `;
export function useGetLlMsQuery(baseOptions?: Apollo.QueryHookOptions<GetLlMsQuery, GetLlMsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLlMsQuery, GetLlMsQueryVariables>(GetLlMsDocument, options);
      }
export function useGetLlMsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLlMsQuery, GetLlMsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLlMsQuery, GetLlMsQueryVariables>(GetLlMsDocument, options);
        }
export type GetLlMsQueryHookResult = ReturnType<typeof useGetLlMsQuery>;
export type GetLlMsLazyQueryHookResult = ReturnType<typeof useGetLlMsLazyQuery>;
export type GetLlMsQueryResult = Apollo.QueryResult<GetLlMsQuery, GetLlMsQueryVariables>;
export const GetLoginInfoDocument = gql`
    query getLoginInfo {
  login {
    data {
      attributes {
        signIn {
          description
          picture {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
        signUp {
          description
          picture {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetLoginInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetLoginInfoQuery, GetLoginInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoginInfoQuery, GetLoginInfoQueryVariables>(GetLoginInfoDocument, options);
      }
export function useGetLoginInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoginInfoQuery, GetLoginInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoginInfoQuery, GetLoginInfoQueryVariables>(GetLoginInfoDocument, options);
        }
export type GetLoginInfoQueryHookResult = ReturnType<typeof useGetLoginInfoQuery>;
export type GetLoginInfoLazyQueryHookResult = ReturnType<typeof useGetLoginInfoLazyQuery>;
export type GetLoginInfoQueryResult = Apollo.QueryResult<GetLoginInfoQuery, GetLoginInfoQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($filters: NotificationFiltersInput) {
  notifications(
    pagination: {limit: -1}
    filters: $filters
    sort: "effectiveDate:desc"
  ) {
    data {
      id
      attributes {
        effectiveDate
        title
        description
        color
      }
    }
  }
}
    `;
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetPaymentHistoryDocument = gql`
    query getPaymentHistory($filters: PaymentHistoryFiltersInput) {
  paymentHistories(
    filters: $filters
    sort: "createdAt:desc"
    pagination: {limit: -1}
  ) {
    data {
      attributes {
        type
        duration
        price
        username
        createdAt
      }
    }
  }
}
    `;
export function useGetPaymentHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>(GetPaymentHistoryDocument, options);
      }
export function useGetPaymentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>(GetPaymentHistoryDocument, options);
        }
export type GetPaymentHistoryQueryHookResult = ReturnType<typeof useGetPaymentHistoryQuery>;
export type GetPaymentHistoryLazyQueryHookResult = ReturnType<typeof useGetPaymentHistoryLazyQuery>;
export type GetPaymentHistoryQueryResult = Apollo.QueryResult<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>;
export const GetPromptsForLibraryDocument = gql`
    query getPromptsForLibrary($filters: PromptFiltersInput) {
  prompts(filters: $filters, pagination: {limit: -1}) {
    data {
      id
      attributes {
        key
        description
        shortDescription
        color
        displayName
        effectiveDate
        costSaved
        revenueGrowth
        isPremium
        timeSaved
        promptOwner
        context {
          categoryKey
          categoryDisplayName
          options {
            displayValue
            value
            key
          }
        }
        tags {
          data {
            attributes {
              name
              tags_category {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetPromptsForLibraryQuery(baseOptions?: Apollo.QueryHookOptions<GetPromptsForLibraryQuery, GetPromptsForLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromptsForLibraryQuery, GetPromptsForLibraryQueryVariables>(GetPromptsForLibraryDocument, options);
      }
export function useGetPromptsForLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromptsForLibraryQuery, GetPromptsForLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromptsForLibraryQuery, GetPromptsForLibraryQueryVariables>(GetPromptsForLibraryDocument, options);
        }
export type GetPromptsForLibraryQueryHookResult = ReturnType<typeof useGetPromptsForLibraryQuery>;
export type GetPromptsForLibraryLazyQueryHookResult = ReturnType<typeof useGetPromptsForLibraryLazyQuery>;
export type GetPromptsForLibraryQueryResult = Apollo.QueryResult<GetPromptsForLibraryQuery, GetPromptsForLibraryQueryVariables>;
export const GetPromptsHistoryDocument = gql`
    query getPromptsHistory($filters: RequestHistoryFiltersInput) {
  requestHistories(
    filters: $filters
    pagination: {limit: -1}
    sort: "createdAt:desc"
  ) {
    data {
      id
      attributes {
        users_permissions_user {
          data {
            id
          }
        }
        input
        userInput
        promptUsed
        contexts(pagination: {limit: -1}) {
          categoryDisplayName
          categoryKey
          options {
            value
            displayValue
            key
          }
        }
        response_histories(pagination: {limit: -1}, sort: "createdAt:desc") {
          data {
            id
            attributes {
              llmInputTemplate
              rating
              available_llm {
                data {
                  id
                  attributes {
                    ...AvailableLLM
                  }
                }
              }
              key
              cost
              llmInput
              llmResponse
              createdAt
            }
          }
        }
        createdAt
      }
    }
  }
}
    ${AvailableLlmFragmentDoc}`;
export function useGetPromptsHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetPromptsHistoryQuery, GetPromptsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromptsHistoryQuery, GetPromptsHistoryQueryVariables>(GetPromptsHistoryDocument, options);
      }
export function useGetPromptsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromptsHistoryQuery, GetPromptsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromptsHistoryQuery, GetPromptsHistoryQueryVariables>(GetPromptsHistoryDocument, options);
        }
export type GetPromptsHistoryQueryHookResult = ReturnType<typeof useGetPromptsHistoryQuery>;
export type GetPromptsHistoryLazyQueryHookResult = ReturnType<typeof useGetPromptsHistoryLazyQuery>;
export type GetPromptsHistoryQueryResult = Apollo.QueryResult<GetPromptsHistoryQuery, GetPromptsHistoryQueryVariables>;
export const GetSeasonTicketDocument = gql`
    query getSeasonTicket($id: ID) {
  subscriptionType(id: $id) {
    data {
      id
      attributes {
        price
        description
        subscriptionItem {
          id
          key
          value
          displayValue
          prompts
          backgroundColor
        }
      }
    }
  }
}
    `;
export function useGetSeasonTicketQuery(baseOptions?: Apollo.QueryHookOptions<GetSeasonTicketQuery, GetSeasonTicketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonTicketQuery, GetSeasonTicketQueryVariables>(GetSeasonTicketDocument, options);
      }
export function useGetSeasonTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonTicketQuery, GetSeasonTicketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonTicketQuery, GetSeasonTicketQueryVariables>(GetSeasonTicketDocument, options);
        }
export type GetSeasonTicketQueryHookResult = ReturnType<typeof useGetSeasonTicketQuery>;
export type GetSeasonTicketLazyQueryHookResult = ReturnType<typeof useGetSeasonTicketLazyQuery>;
export type GetSeasonTicketQueryResult = Apollo.QueryResult<GetSeasonTicketQuery, GetSeasonTicketQueryVariables>;
export const GetTagsCategoryDocument = gql`
    query getTagsCategory($filters: TagsCategoryFiltersInput) {
  tagsCategories(filters: $filters, pagination: {limit: -1}, sort: "order:asc") {
    data {
      id
      attributes {
        name
        order
        tags {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetTagsCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsCategoryQuery, GetTagsCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsCategoryQuery, GetTagsCategoryQueryVariables>(GetTagsCategoryDocument, options);
      }
export function useGetTagsCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsCategoryQuery, GetTagsCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsCategoryQuery, GetTagsCategoryQueryVariables>(GetTagsCategoryDocument, options);
        }
export type GetTagsCategoryQueryHookResult = ReturnType<typeof useGetTagsCategoryQuery>;
export type GetTagsCategoryLazyQueryHookResult = ReturnType<typeof useGetTagsCategoryLazyQuery>;
export type GetTagsCategoryQueryResult = Apollo.QueryResult<GetTagsCategoryQuery, GetTagsCategoryQueryVariables>;
export const GetUserNotificationsDocument = gql`
    query getUserNotifications($input: UsersNotificationFiltersInput!) {
  usersNotifications(filters: $input) {
    data {
      id
    }
  }
}
    `;
export function useGetUserNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
      }
export function useGetUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
        }
export type GetUserNotificationsQueryHookResult = ReturnType<typeof useGetUserNotificationsQuery>;
export type GetUserNotificationsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationsLazyQuery>;
export type GetUserNotificationsQueryResult = Apollo.QueryResult<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>;
export const GetUserNotificationCustomDocument = gql`
    query getUserNotificationCustom($input: GetUserNotificationsInput!) {
  getUserNotifications(input: $input) {
    id
    description
    effectiveDate
    title
    isNew
    color
    createdAt
    publishedAt
    updatedAt
  }
}
    `;
export function useGetUserNotificationCustomQuery(baseOptions: Apollo.QueryHookOptions<GetUserNotificationCustomQuery, GetUserNotificationCustomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationCustomQuery, GetUserNotificationCustomQueryVariables>(GetUserNotificationCustomDocument, options);
      }
export function useGetUserNotificationCustomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationCustomQuery, GetUserNotificationCustomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationCustomQuery, GetUserNotificationCustomQueryVariables>(GetUserNotificationCustomDocument, options);
        }
export type GetUserNotificationCustomQueryHookResult = ReturnType<typeof useGetUserNotificationCustomQuery>;
export type GetUserNotificationCustomLazyQueryHookResult = ReturnType<typeof useGetUserNotificationCustomLazyQuery>;
export type GetUserNotificationCustomQueryResult = Apollo.QueryResult<GetUserNotificationCustomQuery, GetUserNotificationCustomQueryVariables>;
export const GetUsersMenuDocument = gql`
    query getUsersMenu($filter: UsersMenuFiltersInput, $sort: [String] = ["order:ASC"]) {
  usersMenus(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        menu_groups(pagination: {limit: -1}, sort: $sort) {
          data {
            id
            attributes {
              order
              name
              menu_navigations(pagination: {limit: -1}, sort: $sort) {
                data {
                  id
                  attributes {
                    order
                    prompt_type {
                      data {
                        id
                        attributes {
                          displayName
                          publishedAt
                          key
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetUsersMenuQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersMenuQuery, GetUsersMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersMenuQuery, GetUsersMenuQueryVariables>(GetUsersMenuDocument, options);
      }
export function useGetUsersMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersMenuQuery, GetUsersMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersMenuQuery, GetUsersMenuQueryVariables>(GetUsersMenuDocument, options);
        }
export type GetUsersMenuQueryHookResult = ReturnType<typeof useGetUsersMenuQuery>;
export type GetUsersMenuLazyQueryHookResult = ReturnType<typeof useGetUsersMenuLazyQuery>;
export type GetUsersMenuQueryResult = Apollo.QueryResult<GetUsersMenuQuery, GetUsersMenuQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    ...User
  }
}
    ${UserFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ModalContentsDocument = gql`
    query modalContents {
  modalContents {
    data {
      attributes {
        name
        title
        subtitle
        description
        text
        button {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useModalContentsQuery(baseOptions?: Apollo.QueryHookOptions<ModalContentsQuery, ModalContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalContentsQuery, ModalContentsQueryVariables>(ModalContentsDocument, options);
      }
export function useModalContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalContentsQuery, ModalContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalContentsQuery, ModalContentsQueryVariables>(ModalContentsDocument, options);
        }
export type ModalContentsQueryHookResult = ReturnType<typeof useModalContentsQuery>;
export type ModalContentsLazyQueryHookResult = ReturnType<typeof useModalContentsLazyQuery>;
export type ModalContentsQueryResult = Apollo.QueryResult<ModalContentsQuery, ModalContentsQueryVariables>;
export const OnboardingsDocument = gql`
    query onboardings($sort: [String] = ["step:ASC"]) {
  onboardings(sort: $sort) {
    data {
      attributes {
        text
        group {
          name
          select(pagination: {limit: 50}) {
            value
            displayValue
          }
        }
      }
    }
  }
}
    `;
export function useOnboardingsQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingsQuery, OnboardingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingsQuery, OnboardingsQueryVariables>(OnboardingsDocument, options);
      }
export function useOnboardingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingsQuery, OnboardingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingsQuery, OnboardingsQueryVariables>(OnboardingsDocument, options);
        }
export type OnboardingsQueryHookResult = ReturnType<typeof useOnboardingsQuery>;
export type OnboardingsLazyQueryHookResult = ReturnType<typeof useOnboardingsLazyQuery>;
export type OnboardingsQueryResult = Apollo.QueryResult<OnboardingsQuery, OnboardingsQueryVariables>;
export const GetPromptTypeDocument = gql`
    query getPromptType($filters: PromptFiltersInput) {
  prompts(filters: $filters, pagination: {limit: -1}) {
    data {
      id
      attributes {
        key
        context(pagination: {limit: -1}) {
          id
          options(pagination: {limit: -1}) {
            id
            key
            value
            displayValue
          }
          categoryKey
          categoryDisplayName
        }
        baseJourneyDisplayValue
        baseJourneyValue
        premiumDefaultLLM
        freeDefaultLLM
        inputPlaceholder
        pageType
        displayName
        availablePrompt {
          id
          available_llm {
            data {
              id
              attributes {
                ...AvailableLLM
              }
            }
          }
          cost
          template
        }
      }
    }
  }
}
    ${AvailableLlmFragmentDoc}`;
export function useGetPromptTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetPromptTypeQuery, GetPromptTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromptTypeQuery, GetPromptTypeQueryVariables>(GetPromptTypeDocument, options);
      }
export function useGetPromptTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromptTypeQuery, GetPromptTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromptTypeQuery, GetPromptTypeQueryVariables>(GetPromptTypeDocument, options);
        }
export type GetPromptTypeQueryHookResult = ReturnType<typeof useGetPromptTypeQuery>;
export type GetPromptTypeLazyQueryHookResult = ReturnType<typeof useGetPromptTypeLazyQuery>;
export type GetPromptTypeQueryResult = Apollo.QueryResult<GetPromptTypeQuery, GetPromptTypeQueryVariables>;
export const ResponseHistoryDocument = gql`
    query responseHistory($id: ID!) {
  responseHistory(id: $id) {
    data {
      id
      attributes {
        ...ResponseHistory
      }
    }
  }
}
    ${ResponseHistoryFragmentDoc}`;
export function useResponseHistoryQuery(baseOptions: Apollo.QueryHookOptions<ResponseHistoryQuery, ResponseHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseHistoryQuery, ResponseHistoryQueryVariables>(ResponseHistoryDocument, options);
      }
export function useResponseHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseHistoryQuery, ResponseHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseHistoryQuery, ResponseHistoryQueryVariables>(ResponseHistoryDocument, options);
        }
export type ResponseHistoryQueryHookResult = ReturnType<typeof useResponseHistoryQuery>;
export type ResponseHistoryLazyQueryHookResult = ReturnType<typeof useResponseHistoryLazyQuery>;
export type ResponseHistoryQueryResult = Apollo.QueryResult<ResponseHistoryQuery, ResponseHistoryQueryVariables>;
export const GetSeasonTicketsDocument = gql`
    query getSeasonTickets($sort: [String] = ["price:ASC"]) {
  subscriptionTypes(sort: $sort) {
    data {
      id
      attributes {
        price
        active
        recommended
        description
        subscriptionItem {
          id
          key
          value
          displayValue
          prompts
          backgroundColor
        }
        paymentId
        advantages {
          value
          id
        }
      }
    }
  }
}
    `;
export function useGetSeasonTicketsQuery(baseOptions?: Apollo.QueryHookOptions<GetSeasonTicketsQuery, GetSeasonTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonTicketsQuery, GetSeasonTicketsQueryVariables>(GetSeasonTicketsDocument, options);
      }
export function useGetSeasonTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonTicketsQuery, GetSeasonTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonTicketsQuery, GetSeasonTicketsQueryVariables>(GetSeasonTicketsDocument, options);
        }
export type GetSeasonTicketsQueryHookResult = ReturnType<typeof useGetSeasonTicketsQuery>;
export type GetSeasonTicketsLazyQueryHookResult = ReturnType<typeof useGetSeasonTicketsLazyQuery>;
export type GetSeasonTicketsQueryResult = Apollo.QueryResult<GetSeasonTicketsQuery, GetSeasonTicketsQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: ID!) {
  usersPermissionsUser(id: $id) {
    data {
      attributes {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;